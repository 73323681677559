<template>
  <div
    :class="setClass()"
    class="custom-btn"
    @click="onClick"
  >
    <div
      v-if="!img || !text"
      class="pointer-events-none"
    >
      <div v-if="!img">
        {{ text }}
      </div>
      <img
        v-else
        :src="`/images/${img}`"
        :alt="altImg"
      >
    </div>
    <div
      v-else
      style="display: flex; justify-content: center; align-items: center;"
      class="pointer-events-none"
    >
      <img
        :src="`/images/${img}`"
        :alt="altImg"
        style="top: 0; padding-right: 6px;"
      >
      <div> {{ text }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Button',
  props: {
    text: {
      type: String,
      default: 'Button',
    },
    img: {
      type: String,
    },
    typeClass: {
      type: String,
      validator: val => ['red', 'outline-red', 'green', 'yellowgold', 'outline-tr', 'disable'].includes(val),
      default: 'red',
    },
    customPadding: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      altImg: 'logo-btn.png',
    };
  },
  methods: {
    onClick(event) {
      if (!this.disabled) {
        this.$emit('click', event);
      }
    },
    setClass() {
      if (this.customPadding) {
        if (this.disabled) {
          return `custom-padding ${this.typeClass} disabled`;
        }
        return `custom-padding ${this.typeClass}`;
      }
      if (this.disabled) {
        return `${this.typeClass} disabled`;
      }
      return this.typeClass;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
