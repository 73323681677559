/** @type {Array<InformationInfo>} */
const INFORMATION = [
  {
    title: 'Syarat & Ketentuan Surat Karantina di Lion Parcel',
    desc:
          "<ol style='margin-left: 20px;'><li>Surat  berlaku 1x pengiriman dan maks berat 50kg</li><li>Tidak bisa klaim jika tanaman <b>mati/layu</b></li><li>Pengiriman Regpack dengan penambahan estimasi 1 hari</li><li>Pembuatan surat Karantina untuk Jawa dan Sumatera bagian Selatan bisa dibantu pengurusan di Hub Jakarta dan Hub Surabaya, dan untuk area Makassar dan sekitar nya bisa di Hub Makassar</li></ol>",
    img: 'quarantine/ics_f_chat_question.svg',
    icoBgColor: '#E3FCEF',
  },
  {
    title: 'Tujuan Surat Karantina',
    desc:
          "<ol style='margin-left: 20px;'><li>Bukti Tanaman sudah dikarantina</li><li>Mempermudah proses pengiriman (Via udara)</li><li>Pencegahan organisme pengganggu tanaman tersebar</li><li>Mengikuti Peraturan Pemerintah No. 29 Tahun 2023</li></ol>",
    img: 'quarantine/ics_f_file.svg',
    icoBgColor: '#E3FCEF',
  },
  {
    title: 'Surat Karantina untuk Jenis Tanaman',
    desc:
          "<ul style='margin-left: 20px; list-style-type: disc;'><li>Bibit tanaman</li><li>Bunga potong</li><li>Tanaman hias</li><li>Selain tanaman, pengiriman barang yang memerlukan surat karantina adalah Ikan & Vaksin Hewan. Untuk lebih detailnya silakan hubungi Liona Customer Service Lion Parcel</li></ul>",
    img: 'quarantine/ics_f_cherry.svg',
    icoBgColor: '#E3FCEF',
  },
  {
    title: 'Biaya Pembuatan Surat Karantina',
    desc:
          'Biaya mulai dari <b>Rp10.000</b>',
    img: 'quarantine/ics_f_money_alt.svg',
    icoBgColor: '#E3FCEF',
  },
];

/** @type {Array<ProcessInfo>} */
const PROCESS = [
  {
    title: 'Buat Surat Karantina',
    desc:
          'Untuk Jawa dan Sumatera bagian Selatan bisa dibantu pengurusan di Hub Jakarta dan Hub Surabaya, dan untuk area Makassar dan sekitar nya bisa di Hub Makassar',
    number: '1',
    numberColor: '#36B37E',
    icoBgColor: '#E3FCEF',
  },
  {
    title: 'Packing Tanaman',
    desc:
          'Siapkan tanaman yang sudah dipacking dengan rapi & aman sesuai prosedur.',
    number: '2',
    numberColor: '#36B37E',
    icoBgColor: '#E3FCEF',
  },
  {
    title: 'Drop Paket atau  Pick Up',
    desc:
          'Anda bisa menggunakan Drop Paket atau Pick Up untuk mengirim paket tanaman.',
    number: '3',
    numberColor: '#36B37E',
    icoBgColor: '#E3FCEF',
  },
  {
    title: 'Pembayaran',
    desc:
          'Segera selesaikan tagihan pembayaranmu untuk melanjutkan  proses pengiriman',
    number: '4',
    numberColor: '#36B37E',
    icoBgColor: '#E3FCEF',
  },
];

module.exports = { INFORMATION, PROCESS };
