<template>
  <div class="quarantine-section">
    <BreadCrumb class="top-left" />

    <!-- Banner Header -->
    <div class="quarantine-section__banner-header do-flex">
      <div class="quarantine-section__banner-header__text do-flex-col">
        <div class="quarantine-section__banner-header__text__title">
          Kirim Tanaman Mudah,
        </div>
        <div class="quarantine-section__banner-header__text__desc do-flex-col">
          <div>Dengan Surat Karantina!</div>
        </div>
      </div>
      <ImageLazy
        :src="imgSrcBannerHeader"
        alt="quarantine_banner_header.png"
        style="position: relative;"
        class="do-flex"
        @error="(event) => isErrorImage(event, 'quarantine-banner')"
      />
    </div>
    <!-- Banner Header -->

    <!-- Quarantine Sub -->
    <div class="quarantine-section__sub do-flex">
      <div class="quarantine-section__sub__content do-flex-col">
        <div class="quarantine-section__sub__content__title">
          Apa itu Surat Karantina?
        </div>
        <div class="quarantine-section__sub__content__desc">
          Surat yang dibutuhkan logistik untuk memperoleh izin pengiriman tanaman saat pemeriksaan di bandara dan sebagai bukti tanaman sudah di karantina.
        </div>
      </div>
      <ImageLazy
        :src="imgSrcQuarantineSub"
        alt="quarantine_sub.png"
        class="ill-img"
        @error="(event) => isErrorImage(event, 'quarantine-sub')"
      />
    </div>
    <!-- Quarantine Sub -->

    <!-- Information List -->
    <listPoint
      :contents-list="LIST_CONTENT.INFORMATION"
    />
    <!-- Information List -->

    <!-- Process List -->
    <listPoint
      :contents-list="listProcess"
      :title="'Bagaimana Cara Pengiriman Tanaman di <span>Lion Parcel</span> ?'"
      :custom-gradient-background="'linear-gradient(180deg, rgba(122, 202, 144, 0.05) 0%, rgba(122, 202, 144, 0) 100%);'"
    />
    <!-- Process List -->

    <!-- Video -->
    <!-- <div class="quarantine-section__video do-flex">
      <div class="quarantine-section__video__text do-flex-col">
        <div class="quarantine-section__video__text__title">
          <div>Cara Kirim Tanaman Anti Ribet, Ini Rahasianya</div>
        </div>
        <div class="quarantine-section__video__text__desc">
          Tonton video selengkapnya disini
        </div>
      </div>
      <div class="quarantine-section__video__wrapper-video">
        <div class="quarantine-section__video__wrapper-video__thumbnail-youtube">
          <iframe
            allowfullscreen=""
            player="html5"
            src="https://www.youtube.com/embed/0bjE22u91-U"
            style="border: none; overflow: hidden;"
            title=""
          />
        </div>
      </div>
    </div> -->
    <!-- Video -->

    <!-- Banner Footer -->
    <div class="quarantine-section__banner-footer do-flex">
      <div class="quarantine-section__banner-footer__text do-flex-col">
        <div class="quarantine-section__banner-footer__text__title do-flex-col">
          <div>Informasi Lebih Lanjut?</div>
        </div>
        <div class="quarantine-section__banner-footer__text__faq">
          Silahkan cek <span @click="goToFAQPage()">FAQ</span> atau hubungi Customer Service
        </div>
        <div class="quarantine-section__banner-footer__text__button">
          <Button
            text="Customer Service"
            type-class="red"
            @click="triggerToCS()"
          />
        </div>
      </div>
      <ImageLazy
        :src="imgSrcBannerFooter"
        alt="quarantine_banner_footer.png"
        @error="(event) => isErrorImage(event, 'quarantine-sub')"
      />
    </div>
    <!-- Banner Footer -->
  </div>
</template>

<script>
import BreadCrumb from '@/components/new-breadcrumb/BreadCrumb';
import Button from '../../components/new-button/Button';
import mixinMobile from '../../misc/mixinMobile';
import mixinMetaInfo from '../../misc/mixinMetaInfo';
import listPoint from '@/components/new-profit-and-registration/ProfitAndRegistrationContent';
import LIST_CONTENT from './app/constants/list';
import ImageLazy from '@/components/ImageLazy/index.vue';
import { setImageDefault } from '@/components/ImageLazy/util';

export default {
  name: 'Karantina',
  components: {
    BreadCrumb, Button, listPoint, ImageLazy,
  },
  mixins: [mixinMetaInfo, mixinMobile],
  data() {
    return {
      searchMetaInfo: '_karantina', // from mixinMetaInfo, path tapi / dengan _ ex: /about jadi _about
      LIST_CONTENT,
    };
  },
  computed: {
    listProcess() {
      const mobile = LIST_CONTENT.PROCESS;
      const desktop = [...LIST_CONTENT.PROCESS];
      let final = null;
      if (this.windowWidth > 599) {
        const temp = desktop[1];
        desktop[1] = desktop[2];
        desktop[2] = temp;
        final = desktop;
      } else {
        final = mobile;
      }
      return final;
    },
    imgSrcBannerFooter() {
      return this.windowWidth <= 599
        ? '/images/quarantine/bg_quarantine_footer_mobile.webp'
        : '/images/quarantine/bg_quarantine_footer.webp';
    },
    imgSrcBannerHeader() {
      return this.windowWidth <= 599
        ? '/images/quarantine/bg_quarantine_header_mobile.webp'
        : '/images/quarantine/bg_quarantine_header.webp';
    },
    imgSrcQuarantineSub() {
      return this.windowWidth <= 599
        ? '/images/quarantine/quarantine_sub_mobile.webp'
        : '/images/quarantine/quarantine_sub.webp';
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    goToFAQPage() {
      this.$router.push('/faq');
    },
    triggerToCS() {
      window.open('https://wa.me/6281119600999');
    },
    isErrorImage(event, addClass) {
      setImageDefault(event, addClass);
    },
  },
  metaInfo() {
    return this.SEO({
      channel: 'channel',
      title: this.metaInfo.meta_info_title,
      description: this.metaInfo.meta_info_description,
      keywords: this.metaInfo.meta_info_keyword,
      publishedTime: this.metaInfo.meta_info_publish_time,
      image: this.metaInfo.attachment_meta_info_photo_url,
    });
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
